import React, { useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { NumberContext } from "../content/NumberContext";
import { PuffLoader } from "react-spinners";
import slugify from "react-slugify";
import azercell from "../assets/image/azercell.png" ;
import gift from "../assets/image/gift.gif" ;
import translations from "../data/langData";
import { LanguageContext } from "../context/LanguageContext";

const Numbers = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { numbers } = useContext(NumberContext);
  const { number } = useParams();
  const numberSplit = number.split("-")[0];
  const numberSplitName = number.split("-")[1];
  const filteredNumber = numbers.filter((item) => item.mode == numberSplit);
  return (
    <>
      <section className="numbers my-5">
        <h1 className="text-center fs-3">
          {numberSplitName.toUpperCase()} {t.numbers.toUpperCase()}
        </h1>
        <div className="container">
          <div className="row">
            {filteredNumber.length == 0 ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <PuffLoader color="#2abee7" size={70} />
              </div>
            ) : (
              filteredNumber.map((item) => {
                const showImg = item.dq == 0 && item.gb == 0 ;
                return (
                  <NavLink
                  to={`/order/${slugify(`${item.id}-${item.sim}`)}`}
                  data-aos="fade-up"
                  className="text-dark mb-3 mt-3"
                  style={{ textDecoration: "none" }}
                >
                  <div className="col-lg-12  col-sm-12 col-12 g-3" key={item.id}>
                    <div
                      className="numberSection d-flex justify-content-between"
                      style={{ background: "#fff" }}
                    >
                      <div className="outline">
                        <div className="d-flex justify-content-between align-items-center p-2">
                          <div>
                          <p
                        className="text-center ms-2 fs-4 text-dark "
                        style={{ margin: "0" }}
                      >
                        <img
                          src={azercell}
                          alt=""
                          className="img-fluid"
                          style={{ width: "30px" }}
                        />{" "}
                        +994 {item.sim} 
                      </p>
                          </div>
                          <div>
                            {" "}
                            <i
                              className="fa-solid fa-square-check me-2"
                              style={{ color: "rgb(152 0 255)" }}
                            ></i>
                            <span>
                              {item.fuck == 0 ? t.fuck : ""}
                            </span>
                          </div>
                          <div className={`${item.dq == 0 && "d-none"}`}>
                            <span>
                              <i
                                className="fa-solid fa-clock me-2"
                                style={{ color: "rgb(152 0 255)" }}
                              ></i>
                              {item.dq} dq
                            </span>
                          </div>
                          <div className={`${item.gb == 0 && "d-none"}`}>
                            <span>
                              <i
                                className="fa-solid fa-globe me-2"
                                style={{ color: "rgb(152 0 255)" }}
                              ></i>
                              {item.gb} gb
                            </span>
                          </div>

                        </div>
                      </div>
                      <p
                        className="text-center ms-2 fs-4 "
                        style={{ margin: "0" }}
                      >
                        
                        <img
                          src={azercell}
                          alt=""
                          className="img-fluid"
                          style={{ width: "30px" }}
                        />{" "}
                        +994 {item.sim}
                      </p>
                      <span className="fs-4" style={{ color: "#000" }}>
                        
                      <img src={gift} alt="" className={`img-fluid me-2 ${showImg && "d-none"}`} style={{width:"40px"}} />
                        {item.price} ₼
                      </span>
                    </div>
                  </div>
                </NavLink>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Numbers;
