const translations = {
    "az": {
      numbers: "Nömrələr",
      topUp: "Top Up",
      about: "Haqqımızda",
      signIn: "Daxil ol",
      search: "Axtar",
      price: "Qiymət",
      sort1: "Əvvəlcə az",
      sort2: "Əvvəlcə çox",
      category: "Classic",
      category1: "Silver",
      category2: "Gold",
      category3: "Platinium",
      copyright: "Sayt müəllif hüquqları ilə qorunur. MySoft tərəfindən hazırlanmışdır",
      simaTitle: "SİMA Rəqəmsal İmza ilə",
      finishTime: "Bitmə vaxtı:",
      simaTitle1: "SİMA mobil tətbiqini açın.",
      simaTitle2: "Skan et düyməsini",
      simaTitle21: "sıxın.",
      simaTitle3: "QR kodu skan edərək daxil olun.",
      simaTitle4: "Sima mobil tətbiqiniz yoxdur?",
      simaDesc: "Hesabınız yoxdursa, mobil telefonunuzla QR kodu skan edərək tətbiqi yükləyib hesab yarada bilərsiniz.",
      back: "Geri",
      fuck: "Fakturasız",
      form: "Məlumatları daxil edin",
      number: "Nömrə",
      dq: "dq",
      name: "Ad,Soyad",
      seria: "Seriya nömrəsi",
      fin: "Fin Kod",
      phone: "Əlaqə nömrəsi",
      note: "Mesajınız",
      onlineTitle: "Ödəniş üsulunu seçin :",
      online: "Onlayn ödəmə",
      fullPayment: "Tam ödəniş",
      partPayment: "Hissəli ödəmə",
      month: "ay",
      orderSend: "SİFARİŞİ TƏSDİQLƏYİN",
      notResult:"Axtardığınız nömrə mövcud deyil və ya artıq satılıb. Bu halda,axtarışı son 4, 5 və ya 6 rəqəmlərlə yenidən cəhd etməyinizi tövsiyyə edirik.",
    
      aboutTitle:"Mynumber rahatlığını hiss et!",
      aboutDesc:"Mynumber.az sizə asan və sürətli imkanlar verir. Addımları təkrarlayaraq istədiyiniz nömrəni asanlıqla əldə edə bilərsiniz.",
     
      aboutTitle1:"Sürətli Axtarış",
      aboutTitle2:"Bir kliklə Sifariş",
      aboutTitle3:"SİMA ilə giriş və ya qeydiyyatsız",

      aboutDesc1:"Axtarmaq istədiyiniz nömrənizin istənilən bir hissəsini daxil  edərək, sürətli şəkildə nəticəni görə bilərsiniz.",
      aboutDesc2:"'Sifariş et' düyməsinə klik edərək, sifariş təsdiqi pəncərəsini görə bilərsiniz.",
      aboutDesc3:"  SİMA ilə giriş edərək sifarişi təsdiq etmək daha rahatdır və ya qeydiyyatsız şəkildə formu doldurarq sifarişinizi təsdiq edə bilərsiniz.",
      successTitle:"Bizi seçdiyiniz üçün təşəkkür edirik !",
      successDesc:"Tezliklə sizinlə əlaqə saxlanılacaq.",
      fuck1:"Fakturalı"
    },
    "en": {
      numbers: "Numbers",
      topUp: "Top Up",
      about: "About Us",
      signIn: "Sign In",
      search: "Search",
      price: "Price",
      sort1: "Sort by Low",
      sort2: "Sort by High",
      category: "Classic",
      category1: "Silver",
      category2: "Gold",
      category3: "Platinum",
      copyright: "Site protected by copyright. Developed by MySoft",
      simaTitle: "SIMA Digital Signature",
      finishTime: "Expiration Time:",
      simaTitle1: "Open SIMA mobile app.",
      simaTitle2: "Press the scan button",
      simaTitle21: "Press.",
      simaTitle3: "Scan the QR code to log in.",
      simaTitle4: "Don't have the SIMA mobile app?",
      simaDesc: "If you don’t have an account, you can scan the QR code with your mobile phone to download the app and create an account.",
      back: "Back",
      fuck: "Without invoice",
      form: "Enter details",
      number: "Number",
      dq: "dq",
      name: "First and Last Name",
      seria: "Serial Number",
      fin: "Fin Code",
      phone: "Contact Number",
      note: "Your Message",
      onlineTitle: "Choose payment method :",
      online: "Online Payment",
      fullPayment: "Full Payment",
      partPayment: "Partial Payment",
      month: "mo",
      orderSend: "CONFIRM ORDER",
      notResult:"The number you are looking for is not available or has already been sold. In this case, we recommend trying the search again with the last 4, 5, or 6 digits.",
          
      aboutTitle:"Feel the convenience of Mynumber!",
      aboutDesc:"Mynumber.az offers you easy and fast opportunities. By repeating the steps, you can easily get the number you want.",
     
      aboutTitle1:"Quick Search",
      aboutTitle2:"One-Click Order",
      aboutTitle3:"Login with SİMA or without registration",

      aboutDesc1:"You can quickly see the results by entering any part of the number you want to search for.",
      aboutDesc2:"By clicking the 'Order Now' button, you can view the order confirmation window.",
      aboutDesc3:"It is easier to confirm your order by logging in with SİMA, or you can confirm your order by filling out the form without registering.",
      successTitle:"Thank you for choosing us !",
      successDesc:"We will contact you shortly.",
      fuck1:"With Invoice"
    },
    "ru": {
      numbers: "Номера",
      topUp: "Пополнить",
      about: "О нас",
      signIn: "Войти",
      search: "Поиск",
      price: "Цена",
      sort1: "Сначала дешевые",
      sort2: "Сначала дорогие",
      category: "Классика",
      category1: "Серебро",
      category2: "Золото",
      category3: "Платина",
      copyright: "Сайт защищен авторским правом. Разработано MySoft",
      simaTitle: "Цифровая подпись SIMA",
      finishTime: "Время истечения:",
      simaTitle1: "Откройте мобильное приложение SIMA.",
      simaTitle2: "Нажмите кнопку сканирования",
      simaTitle21: "Нажмите.",
      simaTitle3: "Сканируйте QR-код для входа.",
      simaTitle4: "У вас нет мобильного приложения SIMA?",
      simaDesc: "Если у вас нет аккаунта, вы можете скачать приложение, сканировав QR-код с мобильного телефона и создать аккаунт.",
      back: "Назад",
      fuck: "Без счета",
      form: "Введите данные",
      number: "Номер",
      dq: "dq",
      name: "Имя, Фамилия",
      seria: "Серийный номер",
      fin: "Фин Код",
      phone: "Контактный номер",
      note: "Ваше сообщение",
      onlineTitle: "Выберите способ оплаты :",
      online: "Онлайн оплата",
      fullPayment: "Полная оплата",
      partPayment: "Частичная оплата",
      month: "мес",
      orderSend: "ПОДТВЕРДИТЕ ЗАКАЗ",
      notResult:"Искомый номер недоступен или уже продан. В этом случае мы рекомендуем повторить поиск, используя последние 4, 5 или 6 цифр.",

      
      aboutTitle:"Почувствуйте удобство Mynumber!",
      aboutDesc:"Mynumber.az предоставляет вам простые и быстрые возможности. Повторяя шаги, вы можете легко получить нужный номер.",
     
      aboutTitle1:"Быстрый поиск",
      aboutTitle2:"Заказ в один клик.",
      aboutTitle3:"Вход через СИМА или без регистрации",

      aboutDesc1:"Вы можете быстро увидеть результат, введя любую часть номера, который вы хотите найти.",
      aboutDesc2:"'Нажав на кнопку 'Заказать', вы можете увидеть окно подтверждения заказа.",
      aboutDesc3:"Вход через SİMA упрощает подтверждение заказа, или вы можете подтвердить заказ, заполнив форму без регистрации.",
      successTitle:"Спасибо, что выбрали нас !",
      successDesc:"Мы скоро с вами свяжемся.",
      fuck1:"С фактурой"
    }
  };
  
  export default translations;
  