import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { NumberContext } from "../content/NumberContext";
import slugify from "react-slugify";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import azercell from "../assets/image/azercell.png";
import gift from "../assets/image/gift.gif";
import Countdown from "react-countdown";
import party from "../assets/image/party.png";

const DiscountMinute = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const navigate = useNavigate();
  const { numbers, loading } = useContext(NumberContext);
  const [localMin] = useState(localStorage.getItem("win"));
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    // localStorage'dan start time-ı alırıq
    const storedStartTime = localStorage.getItem("startTime");

    if (!storedStartTime) {
      // Əgər start time yoxdursa, onu təyin edirik və 5 dəqiqəlik zaman başlatırıq
      const newStartTime = Date.now();
      localStorage.setItem("startTime", newStartTime);
      setStartTime(newStartTime);
    } else {
      // Əgər artıq bir başlanğıc vaxtı varsa, onu təkrar istifadə edirik
      setStartTime(parseInt(storedStartTime, 10));
    }
  }, []);

  const handleCountdownComplete = () => {
    // Countdown bitdikdə istifadəçini ana səhifəyə yönləndiririk
    localStorage.removeItem("startTime");
    localStorage.removeItem("win")
    localStorage.removeItem("30")
    navigate("/"); // Ana səhifəyə yönləndiririk
  };

  useEffect(() => {
    // "win" keyi ilə localStorage'dan məlumatı yoxlayırıq və ona əsasən yönləndiririk
    if (localMin !== "true") {
      navigate("/"); // Əgər uyğun deyilse, ana səhifəyə yönləndiririk
    }
  }, [localMin, navigate]);

  return (
    <>
      <section className="numbers my-5">
        <div className="d-flex justify-content-center align-items-center">
          <img
            src={party}
            alt=""
            className="img-fluid"
            style={{ width: "45px" }}
          />{" "}
          <h1 className="text-center">5000 Dəq və 100gb</h1>
          <img
            src={party}
            alt=""
            className="img-fluid"
            style={{ width: "45px" }}
          />
        </div>
        <div className="container">
          <div className="row mb-5 ">
            {startTime && (
               <Countdown
               overtime={true}
               date={startTime + 1000 * 60 * 5} // 5 dəqiqəlik zaman
               renderer={({ minutes, seconds }) => (
                 <div className={`fs-4 text-start  ${minutes < 1 && "text-danger"}`} >
                   <span className="countdown">
                     {t.finishTime} {minutes}
                   </span>
                   :<span>{seconds < 10 ? `0${seconds}` : seconds}</span>
                 </div>
               )}
               onComplete={handleCountdownComplete}
             />
            )}
            {numbers &&
              numbers
                .filter((i) => i.prize === "1")
                .map((item) => {
                  const showImg = item.dq === 0 && item.gb === 0;
                  return (
                    <NavLink
                      to={`/order/${slugify(`${item.id}-${item.sim}`)}`}
                      className="text-dark mb-3 mt-3"
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="col-lg-12 col-sm-12 col-12 g-3"
                        key={item.id}
                      >
                        <div
                          className="numberSection d-flex justify-content-between"
                          style={{ background: "#fff" }}
                        >
                          <div className="outline">
                            <div className="d-flex justify-content-between align-items-center p-2">
                              <div>
                                <p
                                  className="text-center ms-2 fs-4 text-dark "
                                  style={{ margin: "0" }}
                                >
                                  <img
                                    src={azercell}
                                    alt=""
                                    className="img-fluid"
                                    style={{ width: "30px" }}
                                  />{" "}
                                  +994 {item.sim}
                                </p>
                              </div>
                              <div>
                                <i
                                  className="fa-solid fa-square-check me-2"
                                  style={{ color: "rgb(152 0 255)" }}
                                ></i>
                                <span>
                                  {item.fuck === 0 ? t.fuck : t.fuck1}
                                </span>
                              </div>
                            </div>
                          </div>
                          <p
                            className="text-center ms-2 fs-4 "
                            style={{ margin: "0" }}
                          >
                            <img
                              src={azercell}
                              alt=""
                              className="img-fluid"
                              style={{ width: "30px" }}
                            />{" "}
                            +994 {item.sim}
                          </p>
                          <span className="fs-4" style={{ color: "#000" }}>
                            <img
                              src={gift}
                              alt=""
                              className={`img-fluid me-2 ${
                                showImg && "d-none"
                              }`}
                              style={{ width: "40px" }}
                            />
                            <span>{item.price}₼</span>
                          </span>
                        </div>
                      </div>
                    </NavLink>
                  );
                })}
          </div>
        </div>
      </section>
    </>
  );
};

export default DiscountMinute;
