import React from "react";
import { useEffect, useState } from "react";
import WheelComponent from "react-wheel-of-prizes";
import Confetti from "react-confetti";
import party1 from "../assets/image/party.png";
import sound from "../assets/image/sound.MP3"; // Ses dosyasını doğru yoldan import et
import { NavLink, useNavigate } from "react-router-dom";
const Spin = () => {
  const [win, setWin] = useState("");
  const [close, setClose] = useState(false);
  const [mustSpin, setMustSpin] = useState(false); // Çarkın dönüp dönmediğini kontrol etmek için
  const navigate = useNavigate();
  const segments = ["5000 DƏQ", "100 GB", "Bir daha sına", "30%"];
  const segColors = [
    "rgb(12,155,231)",
    "#35ABB7",
    "rgb(12,155,231)",
    "#35ABB7",
    "rgb(12,155,231)",
  ];

  const onFinished = (winner) => {
    setWin(winner);
  };

  const handleClick = () => {
    setClose(true);
  };

  const playSound = () => {
    const audio = new Audio(sound); // Ses dosyasını yükle
    audio.play().catch((error) => {
      console.log("Ses çalarken bir hata oluştu:", error); // Eğer bir hata olursa, hata mesajı ver
    });
  };

  const handleSpinClick = () => {
    playSound(); // Çark dönmeye başlamadan önce sesi çal
    setMustSpin(true); // Çarkı döndür
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMustSpin(false);
    }, 4000); // Çarkın dönme süresi boyunca sesin bitmesi için timeout ekleniyor

    return () => clearTimeout(timeout);
  }, [mustSpin]);

  const handleGetClick = () => {
    localStorage.setItem("win","true")
    if (win == segments[3]) {
      navigate("/discount30");
    } else if (win == segments[0]) {
      navigate("/discountmin");
    } else if (win == segments[1]) {
      navigate("/discountgb");
    } else {
      navigate("/")
    }
  };

  

  return (
    <>
      <div className="spinApp" style={{width:"100%",height:"100%"}}>
        <div className={`wheelSpin ${close && "d-none"}`}>
          <div className="d-flex justify-content-end p-5">
            <i
              className="fa-solid fa-close text-white fs-3"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            ></i>
          </div>
          <div className="main-content">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <h2 className="text-center mt-4 d-flex justify-content-center align-items-center ">
                      {win.length > 0 ? (
                        <>
                          {" "}
                          <img
                            src={party1}
                            alt=""
                            className="img-fluid"
                            style={{ width: "45px" }}
                          />{" "}
                          <span className="mx-2"> {win} </span>{" "}
                          <img
                            src={party1}
                            alt=""
                            className="img-fluid"
                            style={{ width: "45px", transform: "scaleX(-1)" }}
                          />
                        </>
                      ) : (
                        <>
                          <span>Şansını İndi Yoxla! </span>
                        </>
                      )}
                    </h2>
                  </div>
                </div>
                <div className="col-12">
                  <div onClick={handleSpinClick}>
                    <WheelComponent
                      segments={segments}
                      segColors={segColors}
                      winningSegment="MM"
                      onFinished={(winner) => onFinished(winner)}
                      contrastColor="white"
                      buttonText=""
                      isOnlyOnce={win == segments[2] ? false : true}
                      size={290}
                      upDuration={500}
                      downDuration={600}
                      fontFamily="Helvetica"
                      mustStartSpinning={mustSpin}
                    />
                  </div>
                </div>
                {(win.length > 0 && win !== segments[2])&& (
                  <div className="col-12 bg-primary">
                    <div className="winDesc d-flex justify-content-center align-items-center">
                      {" "}
                      {/* <NavLink to="/discount30"> */}
                      <button onClick={handleGetClick}>Əldə et</button>
                      {/* </NavLink> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(win.length > 0 && win !== segments[2]) && (
        <div className={`App ${close && "d-none"}`}>
          <Confetti />
        </div>
      )}
    </>
  );
};

export default Spin;
