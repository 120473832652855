import { useEffect, useState } from "react";
import WheelComponent from "react-wheel-of-prizes";
import Confetti from "react-confetti";
import party1 from "../assets/image/party.png";
import sound from "../assets/image/sound.MP3"; // Ses dosyasını doğru yoldan import et

const Business = () => {

  return (
    <>
   Business Page
    </>
  );
};

export default Business;
