import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Context yaratmaq
export const NumberContext = createContext();

const NumberProvider = ({ children }) => {
  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // API çağırışı
    axios.get('https://mynumber.az/api/allNumber.php')
      .then((res) => {
        // 50 ilə başlayan nömrələri və başlamayanları ayırmaq
        const numbersWith50 = res.data.filter(item => item.sim.startsWith("50"));
        const otherNumbers = res.data.filter(item => !item.sim.startsWith("50"));

        // 50 ilə başlayanları əvvəl qoyuruq
        const sortedNumbers = [...numbersWith50, ...otherNumbers];

        setNumbers(res.data); // Məlumatları set edin
        setLoading(false);
    
      })
      .catch((err) => {
        setError(err); // Hata varsa error state-i təyin edin
        setLoading(false);
      });
  }, []); // Boş array, yalnız bir dəfə işə düşsün

  return (
    <NumberContext.Provider value={{ numbers, loading, error }}>
      {children}
    </NumberContext.Provider>
  );
};

export default NumberProvider;
